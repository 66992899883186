import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components/macro";
import { connect } from "react-redux";
import {
  successNotification,
  warningNotification,
  errorNotification,
} from "../../actions";

import {
  setOfficerName,
  setVideoFiles,
  setVideoRedactionPurpose,
  appendVideoFiles,
  uploadVideos,
  resetForm,
  fetchVideos,
  getUploadProgress,
  setUploading,
  setUploadingProgress,
} from "../../actions/redaction.actions";
import InputField from "../../components/common/Form/InputField";
import Dropzone from "../common/Form/Dropzone";
import Button from "../../components/common/Button";
import { colors } from "../../components/themes/base";

const MAX_FILE_SIZE = 13 * 1024 * 1024 * 1024; // 13GB

const buttonProps = {
  width: "140px",
  height: "48px",
  fontSize: "1.6rem",
  background: colors.brand,
  boxShadow: "0 1rem 2rem 0 rgba(124, 160, 73, 0.15)",
  color: "#FFFFFF",
  textTransform: "uppercase",
};

const FormWrapper = styled.div`
  box-sizing: border-box;
  background: ${(props) => props.background};
  width: calc(100% - 10rem); /* minus sidebar width */
  border-radius: 3px;
  margin: 2rem 5rem;
  padding-top: 3rem;
  margin-bottom: 0;
  display: flex;
  justify-content: center;

  form {
    box-sizing: border-box;
    width: 100%;
    min-height: calc(100vh - 175px);
    margin: 0 auto;
    padding: 0 10rem;
    font-family: ${(props) => props.fontFamily};
    color: ${(props) => props.fontColor};
    display: flex;
    flex-direction: column;

    .buttons {
      box-sizing: border-box;
      padding-bottom: 3rem;
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }

    @media (max-width: ${(props) => `${props.mdBreakpoint}px`}) {
      width: 100%;
    }
  }
`;

const validateInput = (validationInput, state) => {
  const items = Object.entries(validationInput);
  const error = {};

  items.forEach(([label, item]) => {
    if (!item || !item.length) {
      error[label] = "Please fill this field";
    }
  });

  if (Object.keys(error).length > 0) {
    const newState = { ...state, error };
    return newState;
  }

  return false;
};

class AddVideo extends Component {
  state = {
    error: {},
  };
  getUpProgress = (redactionId) => {
    const {
      getUploadProgress,
      setUploadingProgress,
      setUploading,
      resetForm,
      fetchVideos,
      errorNotification,
    } = this.props;

    const checkStatus = setInterval(async () => {
      const data = await getUploadProgress(redactionId);
      if (data) {
        const { status, realProgress } = data;
        if (status === "Success") {
          clearInterval(checkStatus);
          setUploadingProgress(0);
          setUploading(false);
          resetForm();
          fetchVideos();
          this.props.history.push("/video/raw/videos");
        } else {
          if (realProgress) setUploadingProgress(realProgress);
        }
      } else {
        clearInterval(checkStatus);
        setUploadingProgress(0);
        setUploading(false);
        errorNotification({
          title: "Failed to upload video",
          autoDismiss: 1,
        });
      }
    }, 2000);
  };

  checkFileSize = () => {
    let fileSizeExceeds = false;

    for (let video of this.props.videosList) {
      if (video.size > MAX_FILE_SIZE) {
        fileSizeExceeds = true;
      }
    }

    return fileSizeExceeds;
  };

  handleSubmit = async () => {
    const {
      officerName,
      redactionPurpose,
      videosList,
      setUploading,
      uploadVideos,
      warningNotification,
      errorNotification,
    } = this.props;

    const validationInput = {
      officerName,
      redactionPurpose,
      videosList,
    };

    const newState = validateInput(validationInput, this.state);
    if (newState) {
      this.setState(newState);
      return;
    }

    if (
      officerName.replace(/\s/g, "").length === 0 ||
      redactionPurpose.replace(/\s/g, "").length === 0
    ) {
      warningNotification({
        title: "Please fill both Officer name and Redaction purpose",
        autoDismiss: 1,
      });
      return;
    }

    if (videosList.length <= 0) {
      warningNotification({
        title: "Please select some videos",
        autoDismiss: 1,
      });
      return;
    }

    if (this.checkFileSize()) {
      warningNotification({
        title: "File size must be less than 13GB",
        autoDismiss: 1,
      });
      return;
    }

    const formData = new FormData();
    formData.append("officerName", officerName);
    formData.append("purpose", redactionPurpose);
    videosList.map((video) => formData.append("files", video));
    setUploading(true);
    const data = await uploadVideos(formData, videosList);
    if (data?.redactionID) {
      this.getUpProgress(data.redactionID);
    } else {
      setUploading(false);
      errorNotification({
        title: "Failed to upload videos",
        autoDismiss: 1,
      });
    }
  };

  render() {
    const {
      officerName,
      redactionPurpose,
      videosList,
      uploading,
      uploadingProgress,
      setOfficerName,
      setVideoFiles,
      setVideoRedactionPurpose,
      // appendVideoFiles
    } = this.props;

    return (
      <div>
        <div
          style={{
            color: "#142945",
            fontFamily: "Muli",
            fontSize: "2.2rem",
            fontWeight: "700",
            letterSpacing: "0.18px",
            margin: "2.5rem 5rem",
          }}
        >
          Add Video
        </div>
        <FormWrapper {...this.props}>
          <form onSubmit={(e) => e.preventDefault()}>
            <InputField
              required={false}
              label="Officer Name*"
              id="officer-name"
              name="officer-name"
              value={officerName}
              handleChange={(e) => {
                setOfficerName(e.target.value);
                this.setState({ error: {} });
              }}
              error={this.state.error.officerName}
              disabled={this.props.uploading}
            />
            <InputField
              required={false}
              label="Redaction Purpose*"
              id="redaction-purpose"
              name="redaction-purpose"
              value={redactionPurpose}
              handleChange={(e) => {
                setVideoRedactionPurpose(e.target.value);
                this.setState({ error: {} });
              }}
              error={this.state.error.redactionPurpose}
              disabled={this.props.uploading}
            />
            <Dropzone
              accept=".mp4,.avi,.mkv,.wmv,.webm,.3gp,.3gpp,.dma,.vob,.mpg,.mov,.flv,.ogg,.ogv,.drc,.gif,.rmvb,.gifv,.mng,.yuv,.rm,.viv,.amv,.asf,.mpeg,.svi,.3g2,.mxf,.roq,.nsv,.m4p,.m4v,.ts,.mp3"
              thumbWidth={21.2}
              thumbHeight={15}
              files={videosList}
              handleDrop={setVideoFiles}
              setFiles={setVideoFiles}
              multiple={false}
              uploading={uploading}
              uploadProgress={uploadingProgress}
              error={this.state.error.videosList}
              disabled={this.props.uploading}
            />
          </form>
        </FormWrapper>
        <div
          className="buttons"
          style={{
            display: "flex",
            backgroundColor: "#fff",
            justifyContent: "flex-end",
            padding: "2rem 5rem",
            position: "sticky",
            bottom: "0",
          }}
        >
          {uploading ? (
            <Button
              {...buttonProps}
              disabled
              background="transparent"
              color="#777"
              boxShadow="none"
              border="none"
              onClick={() => this.props.history.push("/video/raw/videos")}
            >
              Cancel
            </Button>
          ) : (
            <Button
              {...buttonProps}
              background="transparent"
              color="#777"
              boxShadow="none"
              border="none"
              onClick={() => this.props.history.push("/video/raw/videos")}
            >
              Cancel
            </Button>
          )}
          {uploading ? (
            <Button style={{ pointerEvents: "none" }} {...buttonProps}>
              Uploading...
            </Button>
          ) : (
            <Button {...buttonProps} onClick={() => this.handleSubmit()}>
              Upload
            </Button>
          )}
        </div>
      </div>
    );
  }
}

AddVideo.propTypes = {
  fontFamily: PropTypes.string,
  fontColor: PropTypes.string,
  background: PropTypes.string,
  width: PropTypes.string,
  handleUpload: PropTypes.func,
  handleCancel: PropTypes.func,
  mdBreakpoint: PropTypes.number,
};

AddVideo.defaultProps = {
  fontFamily: '"Muli", sans-serif',
  fontColor: "#142945",
  background: "#FFFFFF",
  width: "90%",
  mdBreakpoint: 900,
};

const mapStateToProps = (state) => ({
  officerName: state.redaction.officerName,
  redactionPurpose: state.redaction.redactionPurpose,
  videosList: state.redaction.videosList,
  uploading: state.redaction.uploading,
  uploadingProgress: state.redaction.uploadingProgress,
});

export default connect(mapStateToProps, {
  setOfficerName,
  setVideoFiles,
  setVideoRedactionPurpose,
  appendVideoFiles,
  uploadVideos,
  resetForm,
  fetchVideos,
  getUploadProgress,
  setUploading,
  setUploadingProgress,
  successNotification,
  warningNotification,
  errorNotification,
})(AddVideo);
